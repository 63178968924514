import 'bootstrap/js/dist/collapse.js';
import 'bootstrap/js/dist/dropdown.js';

(function($) {
    $('.product-select--js').change(function(e){
        let index = $(this).data('index');
        $('.product-loading--js-'+index).removeClass('d-none');
        $.ajax({
            type: 'POST',
            url: my_ajax.ajaxurl,
            dataType: "html", // add data type
            data: { action : 'get_ajax_product', 'product_id': $(this).val() },
            success: function( response ) {
                let responseHTML = $.parseHTML(response);
                $('.product-title--js-'+index).html($(responseHTML).find('.product-title').html());
                $('.product-technical-data--js-'+index).html($(responseHTML).find('.product-technical-data').html());
                $('.product-functions--js-'+index).html($(responseHTML).find('.product-functions').html());
                $('.product-areas--js-'+index).html($(responseHTML).find('.product-areas').html());

                $('.product-loading--js-'+index).addClass('d-none');
                // $(target).find('.posts-wrapper').html( response );
            }
        });
    });
}(jQuery));
